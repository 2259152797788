import { render, staticRenderFns } from "./chat.vue?vue&type=template&id=29781be9&"
import script from "./chat.vue?vue&type=script&lang=js&"
export * from "./chat.vue?vue&type=script&lang=js&"
import style0 from "./chat.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAvatar } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VAvatar,VBtn,VCard,VCardText,VCardTitle,VChip,VContainer,VDialog,VDivider,VFlex,VIcon,VLayout,VList,VListTile,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle,VProgressCircular,VSubheader,VTextField})
