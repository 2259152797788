<template>
  <v-container fluid class="pa-0" fill-height>
    <v-layout
      v-if="loading == false"
      align-space-around
      justify-space-between
      column
      fill-height
    >
      <v-flex xs12 sm12 md12>
        <v-card class="elevation-0">
          <v-layout
            v-if="_.size(client) > 0"
            class="px-3 py-2"
            align-start
            row
            fill-height
          >
            <v-flex class="text-xs-center">
              <AvatarLetter
                class="mt-2 mr-3"
                :text-class="'headline'"
                :text="client.name"
                color="red"
              />
            </v-flex>
            <v-flex xs12>
              <div>
                <h1 class="headline ma-0">
                  <span v-html="ucfwords(client.name)"></span>
                  <span v-if="client.phone !== client.name" class="caption">
                    (<a :href="`tel:+${client.phone}`"
                      >+{{ ucfwords(client.phone) }}</a
                    >)</span
                  >
                </h1>
                <h2
                  v-if="this.$mq !== 'xs'"
                  class="body-1 ma-0 mb-0 grey--text text--darken-1"
                >
                  Ultimo mensaje enviado a las
                  <span class="accent--text font-weight-bold"
                    >{{
                      $moment
                        .unix(client.time)
                        .format('dddd DD-MM-YYYY, hh:mm:ss')
                    }}
                  </span>
                </h2>
                <h2
                  v-else
                  class="body-1 ma-0 mb-0 grey--text text--darken-1 caption"
                >
                  Ultimo mensaje
                  <span class="accent--text font-weight-bold"
                    >{{
                      $moment.unix(client.time).format('DD-MM-YYYY hh:mm:ss')
                    }}
                    hrs
                  </span>
                </h2>
              </div>
            </v-flex>
            <v-flex class="text-xs-right">
              <v-btn
                v-if="showMore"
                class="mx-0 mt-2"
                flat
                icon
                @click="searchUser"
              >
                <v-icon>far fa-user-circle</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-card-title v-else>
            No hay historial
          </v-card-title>
          <v-divider></v-divider>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md12>
        <div
          id="container"
          class="content pt-3"
          :style="`height:${windowHeight}px;overflow:scroll`"
        >
          <div
            v-for="(whatsapp, index) in _.orderBy(messages, ['time'], ['asc'])"
            :key="`${client.name}-${index}`"
            :class="
              whatsapp.from
                ? 'message-wrapper them mb-2 text-xs-left'
                : 'message-wrapper me mb-2 text-xs-right'
            "
            @click="openDate(whatsapp, index)"
          >
            <div
              class="text-wrapper animated fadeIn pb-3 pt-1 px-3 elevation-1 body-1"
            >
              <template v-if="checkBody(whatsapp.body) === 'html'">
                <span>{{ whatsapp.body }}</span
                ><br />
              </template>
              <template v-else-if="checkBody(whatsapp.body) === 'audio'">
                <audio style="width:100%;" controls>
                  <source :src="whatsapp.body" type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
              </template>
              <template v-else-if="checkBody(whatsapp.body) === 'img'">
                <div
                  class="img-minimize"
                  :style="`background-image:url(${whatsapp.body});`"
                  @click="openImgDialog(whatsapp.body)"
                ></div>
              </template>
              <br v-if="!whatsapp.from" />
              <div
                v-if="!whatsapp.from"
                style="display: block;position: absolute;right: 5px;bottom: 2px;font-size: 9px;"
              >
                <span v-if="!whatsapp.status">
                  <v-progress-circular
                    :size="20"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  Enviando
                </span>
                <div v-else>
                  <v-icon :size="8" style="margin-bottom:2px;"> check </v-icon
                  ><v-icon :size="8" style="margin-bottom:2px;"> check </v-icon>
                  Enviado por <strong>{{ whatsapp.read_user }}</strong> <br />
                  <span style="text-transform:capitalize;">
                    {{ $moment.unix(whatsapp.time).format('dd') }}</span
                  >
                  {{
                    $moment.unix(whatsapp.time).format('DD-MM-YYYY, hh:mm:ss')
                  }}
                </div>
              </div>
              <div
                v-else
                style="display: block;position: absolute;right: 5px;bottom: 2px;font-size: 9px;"
              >
                <span v-if="whatsapp.read_user !== ''">
                  Leido por <strong>{{ whatsapp.read_user }}</strong></span
                >
              </div>
            </div>
          </div>

          <template v-for="(online, index) in userOnlines">
            <v-chip v-if="online.id > 0" :key="index" small>
              <AvatarLetter :text="online.name" color="success" />
              <span v-if="online.write"> Escribiendo...</span>
              <span v-else v-text="online.name"></span>
            </v-chip>
          </template>
        </div>
      </v-flex>
      <v-flex xs12 sm12 md12 class="mt-0 px-3 pt-2" style="position:relative;">
        <Picker
          v-show="showEmoji"
          set="emojione"
          :style="{ position: 'absolute', bottom: '70px', right: '45px' }"
          @select="addEmoji"
        />
        <div
          v-show="showOptions"
          :style="{
            position: 'absolute',
            bottom: '70px',
            left: '0px',
            width: '100%'
          }"
        >
          <v-card class="mx-3">
            <v-list-tile
              v-for="(option, index) in optionsFilter"
              :key="index"
              dense
              ripple
              @click="handleOption(option.value)"
            >
              <v-list-tile-content>
                <v-list-tile-title class="body-1"
                  >/{{ option.key }}
                  <span class="caption grey--text">{{
                    option.value
                  }}</span></v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-card>
        </div>
        <v-text-field
          ref="chat"
          v-model="message"
          solo
          placeholder="Escribe un mensaje aquí"
          :loading="waitMessage"
          append-outer-icon="send"
          prepend-icon="call"
          append-icon="face"
          :hide-details="true"
          @click:prepend="callNumber"
          @click:append="openEmoji"
          @click:append-outer="sendMessage"
          @keyup.enter.native="sendMessage"
          @keyup.native="writeSomething"
        >
          <v-icon color="info" v-text="'mdi-check-outline'"></v-icon>
        </v-text-field>
      </v-flex>
    </v-layout>
    <v-layout v-else align-center justify-center row fill-height>
      <v-progress-circular
        :size="70"
        :width="6"
        color="accent"
        indeterminate
      ></v-progress-circular>
    </v-layout>

    <v-dialog v-model="dialog.img" max-width="350px">
      <img
        :src="imgDialog"
        style="width:100%;margin-bottom: -6px;"
        alt="Chat"
      />
    </v-dialog>

    <v-dialog v-model="dialog.showMore" max-width="600px" persistent scrollable>
      <v-card>
        <TitleDialog
          :title="`Cuenta para +${client.phone}`"
          close
          :close-action="() => (dialog.showMore = !dialog.showMore)"
        ></TitleDialog>
        <v-card-text
          v-if="client.user !== undefined"
          class="pa-0"
          style="height:400px;"
        >
          <v-layout align-center justify-center>
            <v-flex>
              <div class="pt-3 px-4 pb-2">
                <h1
                  class="headline font-weight-regular"
                  v-html="ucfwords(client.user.name)"
                ></h1>
                <h1 class="subheading font-weight-light">
                  <a :href="`mailto:${client.user.email}`">{{
                    client.user.email
                  }}</a>
                </h1>
                <h1 class="subheading font-weight-light">
                  <a :href="`tel:${client.user.phone}`">{{
                    client.user.phone
                  }}</a>
                </h1>
              </div>
            </v-flex>
            <v-flex>
              <div class="pt-3 px-4 pb-2">
                <v-btn
                  color="accent"
                  class="mt-0 mb-2"
                  target="_blank"
                  :to="{ name: 'buyerDetail', params: { id: client.user.id } }"
                  block
                  depressed
                  small
                >
                  Cuenta
                </v-btn>
                <v-btn
                  color="warning"
                  class="mt-0 mb-2"
                  target="_blank"
                  :to="{ name: 'buyerOrders', params: { id: client.user.id } }"
                  block
                  depressed
                  small
                >
                  Pedidos
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-list
            v-if="client.user !== undefined && orders.length > 0"
            two-line
          >
            <v-subheader> Pedidos </v-subheader>
            <template v-for="(order, key) in orders">
              <v-list-tile
                :key="key"
                avatar
                :to="{ name: 'orderIndex', params: { id: order.id } }"
                target="_blank"
              >
                <v-list-tile-avatar>
                  <v-avatar
                    v-if="order.status === 4"
                    :size="40"
                    color="grey lighten-4"
                  >
                    <img :src="order.photoSmall" alt="avatar" />
                  </v-avatar>
                  <template v-else>
                    <AvatarLetter
                      :text="order.statusDetail.name"
                      color="secondary"
                    />
                  </template>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title
                    v-html="`${order.id} (${order.statusDetail.name})`"
                  ></v-list-tile-title>
                  <v-list-tile-sub-title
                    v-html="
                      `${$options.filters.moment(
                        order.date_shipping,
                        'DD MMMM YYYY'
                      )} - ${
                        order.schedule
                      } - Total: ${$options.filters.toPrice(order.total)}`
                    "
                  ></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
          <template v-else>
            No tiene pedidos ingresados
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { Picker } from 'emoji-mart-vue'
import AvatarLetter from '../general/avatarLetter.vue'
import TitleDialog from '../useful/titleDialog.vue'
import { firestore } from '../../fireStore'
import { GET_API, GET_SEARCH_BUYER } from '../../config'
import { db } from '../../firebase'

export default {
  name: 'Whatsapp',
  components: { AvatarLetter, Picker, TitleDialog },
  props: [
    'numberActive',
    'activeMore',
    'clientActive',
    'showMore',
    'userActives',
    'getNumbers'
  ],
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    notification() {
      const notifications = this.$store.getters.getNotifications
      return notifications
    },
    userOnlines() {
      const self = this
      if (self.userActives.length > 0) {
        const filters = self._.filter(self.userActives, o => {
          if (o !== undefined) {
            return o.phone === self.numberActive && self.user.id !== o.id
          }
        })
        return filters
      }
      return []
    }
  },
  watch: {
    numberActive(e) {
      console.log('numberActive =>', e)
      this.getMessages(e)
    },
    clientActive() {
      this.windowHeight = window.innerHeight - 240
    },
    message(e) {
      const messages = e
      const messagesSplit = messages.split('/')

      this.optionsFilter = this._.filter(this.options, (o, key) => {
        if (
          messagesSplit[messagesSplit.length - 1] !== '' &&
          messages.includes('/')
        ) {
          return this._.includes(key, messagesSplit[messagesSplit.length - 1])
        }
      })

      if (this.optionsFilter.length > 0) {
        this.showOptions = true
      }
      if (e === '') {
        firestore
          .collection('usersActives')
          .doc(`user-${this.user.id}`)
          .update({
            write: false
          })
      }
    },
    notification(e) {
      this.getMessages(this.numberActive)
    }
  },
  methods: {
    async searchUser() {
      try {
        const res = await this.$http.get(GET_SEARCH_BUYER, {
          params: {
            email: this.numberActive
          }
        })
        const rows = res.data
        const user = rows[0]
        if (this._.size(user) > 0) {
          firestore
            .collection('users')
            .doc(this.numberActive)
            .update({
              name: user.name
            })
          this.client.name = user.name
          this.client.user = user
          this.searchOrders(user.id)
          this.getNumbers()
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    searchOrders(id) {
      this.$http({
        method: 'post',
        url: GET_API,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: this.$qs.stringify({
          p: 'getOrdersUser',
          filter: {
            date: '',
            status: 0,
            schedule: ''
          },
          user: id
        })
      })
        .then(res => {
          this.dialog.showMore = true
          this.orders = res.data.json.orders
        })
        .catch(() => {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        })
    },
    getMessages(e) {
      const self = this
      let number = e.replace('+', '')
      number = number.replace(/\s/g, '')
      firestore
        .collection('users')
        .doc(number)
        .collection('messages')
        .orderBy('time', 'desc')
        .get()
        .then(querySnapshot => {
          const messages = []
          querySnapshot.forEach(doc => {
            messages.push(doc.data())
          })
          self.loading = false
          self.messages = messages
          self.scrollToEnd()
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
      firestore
        .collection('users')
        .doc(number)
        .collection('messages')
        .where('read', '==', 0)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            firestore
              .collection('users')
              .doc(self.numberActive)
              .collection('messages')
              .doc(doc.id)
              .update({
                read: 1,
                read_user: self.user.name
              })
          })
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
      firestore
        .collection('users')
        .doc(number)
        .get()
        .then(doc => {
          if (doc.exists) {
            if (!self.dialog.showMore) {
              self.client = doc.data()
            }
          }
        })
        .catch(error => {
          console.log('Error getting document:', error)
        })
      this.setUserActive()
    },
    // eslint-disable-next-line no-undef
    writeSomething: _.debounce(function(e) {
      if (this.message !== '') {
        firestore
          .collection('usersActives')
          .doc(`user-${this.user.id}`)
          .update({
            write: true
          })
      }
    }, 500),
    checkBody(string) {
      if (this._.includes(string, 'firebase')) {
        if (this._.includes(string, '.oga')) {
          return 'audio'
        }
        return 'img'
      }
      return 'html'
    },
    openImgDialog(img) {
      this.imgDialog = img
      this.dialog.img = true
    },
    openDate(message) {
      message.dateShow = !message.dateShow
      // this.messagesList[key].dateShow = true;
    },
    sendMessage() {
      const self = this
      if (self.message !== '') {
        self.waitMessage = true
        self
          .$http({
            method: 'post',
            url: GET_API,
            params: {
              p: 'sendWhatssap',
              phone: self.numberActive,
              message: self.message,
              userName: self.user.name,
              name: self.client.name
            }
          })
          .then(() => {
            self.message = ''
            self.waitMessage = false
            self.getMessages(self.numberActive)
            firestore
              .collection('usersActives')
              .doc(`user-${this.user.id}`)
              .update({
                write: false
              })
          })
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Debe escribir algun mensaje'
        })
      }
    },
    ucfwords(str) {
      str = str
        .toLowerCase()
        .replace(
          /^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g,
          letter => letter.toUpperCase()
        )
      return str
    },
    scrollToEnd() {
      setTimeout(() => {
        if (this.$el.querySelector('#container')) {
          const container = this.$el.querySelector('#container')
          container.scrollTop = container.scrollHeight
          this.see = true
        }
      }, 100)
    },
    openEmoji() {
      this.showEmoji = !this.showEmoji
      this.$refs.chat.focus()
    },
    addEmoji(e) {
      const message = `${this.message}${e.native}`
      this.message = message
    },
    callNumber() {
      // window.location.href=`tel:${this.number}`;
    },
    handleOption(text) {
      const messagesSplit = this.message.split('/')
      messagesSplit[messagesSplit.length - 1] = ''
      this.message = `${this._.join(messagesSplit, '')}${text}`
    },
    getMessagesOptions() {
      const ref = db.ref('command').orderByChild('key')
      ref.once('value', snap => {
        this.options = snap.val()
      })
    },
    setUserActive() {
      const self = this
      if (this.user) {
        firestore
          .collection('usersActives')
          .doc(`user-${this.user.id}`)
          .update({
            phone: self.numberActive ? self.numberActive : 0,
            write: false
          })
      }
    }
  },
  data() {
    return {
      getMessageStatus: null,
      loading: false,
      orders: [],
      dialog: {
        img: false,
        showMore: false
      },
      imgDialog: '',
      windowHeight: window.innerHeight - 260,
      waitMessage: false,
      see: false,
      message: '',
      messages: [],
      client: {},
      showEmoji: false,
      showOptions: false,
      options: {},
      optionsFilter: []
    }
  },
  created() {
    this.getMessagesOptions()
    this.getMessages(this.numberActive)
  }
}
</script>

<style lang="scss">
$blue: #2196f3;
$dingy: #495b6a;
$white: #ffffff;
$purple: #673ab7;
$yellow: #ffa726;
$red: #f44336;
$orange: #ff5722;
$black: #333333;

$nav: 100;

$navHeight: 64px;

$trans: 0.3s ease;

.title-whatsapp {
  background: transparent !important;
}

.img-minimize {
  width: 250px;
  height: 250px;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

.message-wrapper {
  position: relative;
  overflow: hidden;
  padding: 0px 15px 2px 15px;
  width: 100%;
  margin: 0;

  .circle-wrapper {
    height: $navHeight/1.5;
    width: $navHeight/1.5;
    border-radius: 50%;
  }

  .text-wrapper {
    max-width: 50%;
    margin: 0;
    border-radius: 2px;
    position: relative;
    /* word-break: break-all; */
    opacity: 1;
    word-wrap: break-word;
  }

  &.them {
    .circle-wrapper {
      float: left;
    }

    .text-wrapper {
      background: $yellow;
      float: left;
      color: $white;
      min-width: 140px;
      text-align: right;
    }
  }

  &.me {
    .circle-wrapper,
    .text-wrapper {
      background: $orange;
      float: right;
      color: $black;
    }

    .text-wrapper {
      background: $white;
      min-width: 140px;
    }
  }
}

@media (max-width: 560px) {
  .wrapper {
    width: 100%;
    height: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateX(0);

    .inner {
      height: 100%;
      height: 100vh;
    }
  }
}
</style>
