// eslint-disable-next-line import/no-duplicates
import firebase from 'firebase/app'
// eslint-disable-next-line import/no-duplicates
import 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/messaging'

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyDPNfUDx8OrE80BRT6kXEO9UKbt-fPIsCQ',
  authDomain: 'rosalinda-store.firebaseapp.com',
  databaseURL: 'https://rosalinda-store.firebaseio.com',
  projectId: 'rosalinda-store',
  storageBucket: 'rosalinda-store.appspot.com',
  messagingSenderId: '529036824575'
})

export const firestore = firebaseApp.firestore()
export const storage = firebaseApp.storage()
export const auth = firebaseApp.auth()
export const db = firebaseApp.database()

/**
 * Codigo Push Notificacion
 */
/**
  export const messaging = firebaseApp.messaging();
 */
